import './App.css' // You'll need to create this CSS file

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { User, getAuth, onAuthStateChanged } from 'firebase/auth'
import { isMobile, isIOS } from 'react-device-detect'

import About from './components/About' // Add this line
import AdminPage from './components/AdminPage' // Add this import
import Auth from './components/Auth'
import BillingPage from './components/BillingPage' // Add this import
import BrowseStories from './components/BrowseStories'
import MarkdownRenderer from './components/MarkdownRenderer'
import NavBar from './components/NavBar' // Add this import
import SavedStory from './components/SavedStory'
import StoryGenerator from './components/StoryGenerator'
import fscreen from 'fscreen'
import { useAdminStatus } from './hooks/useAdminStatus'

const AppContent: React.FC = () => {
  const [user, setUser] = useState<User | null>(null)
  const { isAdmin, loading: adminLoading } = useAdminStatus()
  const location = useLocation()

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    // Only exit fullscreen when navigating away from the story
    if (fscreen.fullscreenElement && !location.pathname.includes('/story/')) {
      fscreen.exitFullscreen()
    }
  }, [location])

  const isStoryPage = location.pathname.startsWith('/story/')

  if (adminLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      {!isStoryPage && <NavBar />}{' '}
      {/* Only show NavBar if not on a story page */}
      <div className={isStoryPage ? 'App-full' : 'App'}>
        <Routes>
          <Route
            path="/terms-of-service"
            element={
              <MarkdownRenderer filePath="/markdown/terms-of-service.md" />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <MarkdownRenderer filePath="/markdown/privacy-policy.md" />
            }
          />
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Auth />}
          />
          <Route
            path="/create"
            element={user ? <StoryGenerator /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin"
            element={user && isAdmin ? <AdminPage /> : <Navigate to="/login" />}
          />
          <Route path="/story/:storyId" element={<SavedStory />} />
          <Route path="/story/:storyId/:pageNumber" element={<SavedStory />} />
          <Route path="/about" element={<About />} /> {/* Add this line */}
          <Route path="/" element={<BrowseStories />} />
          <Route
            path="/billing"
            element={user ? <BillingPage /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
      {/* App Store Banner for iOS users */}
      {isMobile && isIOS && !isStoryPage && (
        <div className="app-store-banner">
          <div className="app-store-banner-content">
            <div className="app-store-message">
              <span className="app-store-highlight">
                Now available on the App Store!
              </span>
              <span className="app-store-submessage">
                Get the full Iyagi experience
              </span>
            </div>
            <a
              href="https://apps.apple.com/us/app/iyagi-app/id6737707168"
              target="_blank"
              rel="noopener noreferrer"
              className="app-store-button"
            >
              <img
                src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
                alt="Download on the App Store"
                width="135"
                height="40"
              />
            </a>
          </div>
        </div>
      )}
    </>
  )
}

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  )
}

export default App
